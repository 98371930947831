/* eslint-disable @typescript-eslint/no-misused-promises */
import RightRailFlex from 'components/Ad/AdSlot/RightRailFlex';
import { RightRailStickyContainer } from 'components/Ad/AdSlot/Styles';
import Link, { changeRoute } from 'components/Globals/Base/Link';
import FAQ from 'components/FAQ';
import FeatureContentModule from 'components/FeatureContentModule';
import GridContentModule from 'components/GridContentModule';
import YearFilter from 'components/Globals/SlideFilter';
import { gridResponsiveSettings, listResponsiveSettings } from 'components/Globals/SlideFilter/responsiveSettings';
import RankingCompanyFranchiseItem from 'components/RankingCompanyFranchiseItem';
import VodVideo from 'components/VodVideo';
import { FranchisePage } from 'interfaces/content/Ranking';
import RawHtml from 'utils/miscUtils/rawHtml';
import useBreakpoint from 'hooks/useBreakpoint';
import StaticAd from 'components/StaticAdSlot';
import { MenuItemProps } from 'interfaces/navigation/Header';
import DropdownTitle from 'components/DropdownTitle';
import StnVideo from 'components/StnVideo';
import getRankingListTestId from 'constants/testsIds/rankingListPage';
import {
  WrapperTitle,
  CallsToAction,
  DescriptionWrapper,
  DescriptionText,
  DescriptionVideo,
  FeatureContentModuleWrapper,
  ContentModules,
  ListTeaserHighlight,
  Items,
  ListTeaserTitle,
  ListTeaserWrapper,
  RankingListSection,
  RankingListContent,
  ListTeaserCallToAction,
  DescriptionSection,
  FranchiseWrapper,
  ListTeaserTitleWrapper,
  FaqAndMethodologyWrapper,
  ContentWrapper,
  AdWrapper1,
  AdWrapper2,
  GridContentModuleWrapper,
} from './RankingCompanyFranchisePageStyles';

interface RankingCompanyFranchisePageProps {
  franchise: FranchisePage;
  subDomain?: string;
  subMenu?: MenuItemProps[];
}

const RankingCompanyFranchisePage = ({ franchise, subDomain, subMenu }: RankingCompanyFranchisePageProps) => {
  const {
    callsToAction,
    contentModules,
    description,
    faq,
    listTeaser,
    listType,
    lists,
    slug,
    rankingUri,
    title,
    vodVideoId,
    stnVideoId,
    years,
    link,
  } = franchise;
  const trackingData = {
    eventAction: `${title}`,
    eventCategory: 'rankings',
  };
  const isParentPage = link.match(/\d{4}/g) === null;
  const renderFaq = faq.length !== 0 && isParentPage;
  const convertYears = years.map((yr) => Number(yr));

  const isMobile = useBreakpoint('mdMax');

  return (
    <div className='franchise-content'>
      <WrapperTitle>
        <DropdownTitle
          title={title}
          subMenu={subMenu}
          color=''
          separatorPosition=''
          className='dropdown-ranking'
          dataCy={getRankingListTestId('TITLE')}
        />
      </WrapperTitle>
      <FranchiseWrapper>
        <DescriptionSection>
          <DescriptionWrapper>
            <DescriptionText data-cy={getRankingListTestId('DESCRIPTION')}>
              {RawHtml({
                html: description,
              })}
              <CallsToAction data-cy={getRankingListTestId('CALL_TO_ACTION')}>
                {callsToAction.map((callToAction, index) =>
                  (index === 0 ? (
                    <Link
                      className='black-button'
                      href={callToAction.link}
                      key={callToAction.label}
                      type='primaryButton'
                      size={{ default: 'large' }}
                      hasRightArrow={false}
                      trackingData={{
                        ...trackingData,
                        eventLabel: `${callToAction.label}`,
                      }}
                    >
                      {callToAction.label}
                    </Link>
                  ) : (
                    <Link
                      className='link'
                      href={callToAction.link}
                      key={callToAction.label}
                      trackingData={{
                        ...trackingData,
                        eventLabel: `${callToAction.label}`,
                      }}
                      type='bluePrimary'
                    >
                      {callToAction.label}
                    </Link>
                  )))}
              </CallsToAction>
            </DescriptionText>
          </DescriptionWrapper>
          {(vodVideoId || stnVideoId) && (
            <DescriptionVideo>
              {stnVideoId ? (
                <StnVideo videoId={stnVideoId} />
              ) : (
                vodVideoId && (
                  <VodVideo
                    dataCy={getRankingListTestId('VIDEO')}
                    videoId={vodVideoId}
                  />
                )
              )}
            </DescriptionVideo>
          )}
        </DescriptionSection>
        <RankingListSection>
          <RankingListContent>
            <YearFilter
              listType={listType}
              itemClassName='year'
              className={years.length > 1 ? 'year-filter' : ''}
              valueList={years}
              responsiveSettings={listType === 'list' ? listResponsiveSettings : gridResponsiveSettings}
              selectedValue={franchise.year}
              changeSelectedValue={(year: string) => {
                const currentYr = convertYears[0];
                const currentYearCheck = Number(year) === currentYr ? `/ranking/${slug}/` : `/ranking/${slug}/${year}/`;

                return changeRoute(subDomain ? `/${subDomain}/ranking/${slug}/${year}/` : currentYearCheck);
              }}
              trackingData={{ ...trackingData }}
              dataCy={getRankingListTestId('CAROUSEL')}
            />
            <ListTeaserWrapper>
              <ListTeaserTitleWrapper>
                <ListTeaserTitle
                  as='h2'
                  $fontFamily='graphikCond'
                  $size={{ default: 'level2' }}
                >
                  {listTeaser.title}
                </ListTeaserTitle>
                {listTeaser.highlight && (
                  <ListTeaserHighlight
                    as='h2'
                    $fontFamily='graphikCond'
                    $size={{ default: 'level2' }}
                  >
                    {listTeaser.highlight}
                  </ListTeaserHighlight>
                )}
              </ListTeaserTitleWrapper>
              <Items data-cy={getRankingListTestId('TOP_10')}>
                {lists[0].items.map((item) => (
                  <RankingCompanyFranchiseItem
                    key={item.title}
                    franchiseItem={item}
                    showRevenue={!!listTeaser.highlight}
                    trackingData={{
                      ...trackingData,
                      eventLabel: `${franchise.year} : ${listTeaser.title} : ${item.title}`,
                      platformCD: subDomain,
                    }}
                  />
                ))}
              </Items>
              {listType === 'list' && (
                <ListTeaserCallToAction data-cy={getRankingListTestId('CALL_TO_ACTION_EXPLORE_LIST')}>
                  <Link
                    className='black-button'
                    href={`${rankingUri}search/`}
                    key='explore the list'
                    type='primaryButton'
                    size={{ default: 'large' }}
                    hasRightArrow={false}
                    trackingData={{
                      ...trackingData,
                      eventLabel: `explore the list : ${franchise.year}`,
                    }}
                  >
                    EXPLORE THE LIST
                  </Link>
                </ListTeaserCallToAction>
              )}
            </ListTeaserWrapper>
          </RankingListContent>
        </RankingListSection>
        {contentModules.feature && Object.keys(contentModules.feature).length > 0 ? (
          <ContentModules data-cy={getRankingListTestId('MAP')}>
            <FeatureContentModuleWrapper>
              <FeatureContentModule
                feature={contentModules.feature}
                trackingData={trackingData}
              />
            </FeatureContentModuleWrapper>
          </ContentModules>
        ) : null}
        {franchise.showTout !== 0 && (
          <StaticAd
            alt={isMobile ? franchise.toutImageAltMobile : franchise.toutImageAltDesktop}
            imageLink={isMobile ? franchise.toutImageMobile : franchise.toutImageDesktop}
            url={franchise.toutLink}
            isWideBanner={!isMobile}
          />
        )}
        <FaqAndMethodologyWrapper $faq={faq}>
          {renderFaq && (
            <>
              <ContentWrapper>{renderFaq && <FAQ faq={faq} />}</ContentWrapper>
              <AdWrapper2 $faq={faq}>
                <RightRailStickyContainer>
                  <RightRailFlex
                    id={0}
                    sticky
                  />
                </RightRailStickyContainer>
              </AdWrapper2>
            </>
          )}
        </FaqAndMethodologyWrapper>
        {contentModules.grid?.topics && contentModules.grid?.topics.length !== 0 && (
          <ContentModules>
            <GridContentModuleWrapper>
              <GridContentModule
                grid={contentModules.grid}
                dataCy={getRankingListTestId('COVERAGE_MODULE')}
              />
            </GridContentModuleWrapper>
          </ContentModules>
        )}
        {/* ad wrapper 1 only renders if no faq */}
        {!renderFaq && (
          <AdWrapper1>
            <RightRailStickyContainer>
              <RightRailFlex
                dataCy={getRankingListTestId('AD')}
                id={0}
                sticky
              />
            </RightRailStickyContainer>
          </AdWrapper1>
        )}
      </FranchiseWrapper>
    </div>
  );
};

export default RankingCompanyFranchisePage;
